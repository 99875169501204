<template>
  <b-card>
    <b-row>
      <b-col class="text-right">
        <b-button
          variant="primary"
          @click="newModel"
        >
          <FeatherIcon icon="PlusIcon" />
          &nbsp;<span class="text-nowrap">Arquivo</span>
        </b-button>
      </b-col>
    </b-row>
    <Grid
      :fields="fields"
      :items="items"
      edit-button-label="edit"
      new-button-label="new"
      :show-search="false"
      @onChangePage="getData"
    >
      <template
        #cell(action)="data"
        style="width: 20%"
      >
        <div class="px-1 text-center">
          <i2-icon-button
            icon="DownloadIcon"
            variant="outline-primary"
            class="mr-1"
            @click="downloadFile(data.item)"
          />
          <i2-icon-button
            fa-icon="fa-trash"
            variant="outline-danger"
            class="mr-1"
            @click="onDelete(data.item)"
          />

        </div>
      </template>
    </Grid>
    <license-contract-file-form
      :is-active.sync="isActivated"
      :uuid="currentModel"
      :contract-uuid="contractUuid"
      @onSave="onSave()"
      @onCancel="onCancel()"
    />
  </b-card>

</template>

<script>

import LicenseContractFileForm from '@/views/license/LicenseContractFileForm.vue'

export default {
  name: 'LicenseContractFileList',
  components: { LicenseContractFileForm },
  props: {
    contractUuid: {
      required: true,
      type: String,
      default: '',
    },
  },
  data() {
    return {
      search: {
        search: '',
      },
      fields: [
        {
          key: 'name',
          label: 'Nome',
          class: 'w-20',
        },
        {
          key: 'description',
          label: 'Descrição',
          class: 'w-20',
        },

        {
          key: 'action',
          label: 'Ações',
          class: 'w-20 text-center',
        },
      ],
      currentModel: '',
      items: [],
      isActivated: false,
    }
  },
  watch: {
    contractUuid() {
      this.getData(1)
    },
  },
  methods: {
    newModel() {
      this.isActivated = true
    },
    async getData(page) {
      if (!this.contractUuid) {
        return
      }
      this.items = await this.$search.get(
        `contracts/${this.contractUuid}/files`,
        page,
        this.search,
        '',
      )
    },
    setCurrentModel(model) {
      this.isActivated = true
      this.currentModel = model.uuid
    },
    onDelete(model) {
      const route = `contract-files/${model.uuid}`
      this.$apiDelete.execute(
        route,
        'Confirma a exclusão do arquivo',
        'Excluir',
        () => {
          this.$notify.success('Arquivo removido com sucesso.')
          this.getData()
        },
      )
    },
    onSave() {
      this.getData(1)
      this.isActivated = false
      this.currentModel = ''
    },
    onCancel() {
      this.isActivated = false
      this.currentModel = ''
    },
    async downloadFile(item) {
      const response = await this.$http.get(`contract-files/download/${item.uuid}`, { responseType: 'blob' })
      const blob = new Blob([response], { type: response.type })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = item.name
      link.id = 'temp_downloadfile'
      link.click()
      URL.revokeObjectURL(link.href)
    },
  },
}
</script>

<style scoped>

</style>
