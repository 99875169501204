<template>
  <b-card>
    <b-row>
      <b-col class="text-right">
        <b-button
          variant="primary"
          @click="newModel"
        >
          <FeatherIcon icon="PlusIcon" />
          &nbsp;<span class="text-nowrap">Chave</span>
        </b-button>
      </b-col>
    </b-row>
    <Grid
      :fields="fields"
      :items="items"
      edit-button-label="edit"
      new-button-label="new"
      :show-search="false"
      @onChangePage="getData"
    >
      <template
        #cell(key)="data"
      >
        {{ data.item.key.substring(0, 5) + '*****' }}
      </template>
      <template
        #cell(action)="data"
        style="width: 20%"
      >
        <div class="px-1 text-center">
          <i2-icon-button
            icon="EditIcon"
            variant="outline-primary"
            class="mr-1"
            @click="setCurrentModel(data.item)"
          />
          <i2-icon-button
            fa-icon="fa-trash"
            variant="outline-danger"
            class="mr-1"
            @click="onDelete(data.item)"
          />

        </div>
      </template>
    </Grid>
    <license-contract-key-form
      :contract-uuid="contractUuid"
      :is-active.sync="isActivated"
      :uuid="currentContractKey"
      @onSave="onSave()"
      @onCancel="onCancel()"
    />
  </b-card>
</template>

<script>

import LicenseContractKeyForm from '@/views/license/LicenseContractKeyForm.vue'

export default {
  name: 'LicenseContractKeyList',
  components: { LicenseContractKeyForm },
  props: {
    contractUuid: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      search: {
        search: '',
      },
      fields: [
        {
          key: 'key',
          label: 'Chave',
          class: 'w-20',
        },
        {
          key: 'serial_number',
          label: 'Número de Série',
          class: 'w-20',
        },
        {
          key: 'observation',
          label: 'Observação',
          class: 'w-20',
        },

        {
          key: 'action',
          label: 'Ações',
          class: 'w-20 text-center',
        },
      ],
      currentContract: '',
      items: [],
      isActivated: false,
      currentContractKey: '',
    }
  },
  watch: {
    contractUuid() {
      this.getData(1)
    },
  },
  methods: {
    newModel() {
      this.isActivated = true
    },
    async getData(page) {
      if (!this.contractUuid) {
        return
      }
      this.items = await this.$search.get(
        `contracts/${this.contractUuid}/keys`,
        page,
        this.search,
        '',
      )
    },

    setCurrentModel(model) {
      this.isActivated = true
      this.currentContractKey = model.uuid
    },

    onDelete(model) {
      const route = `contract-keys/${model.uuid}`
      this.$apiDelete.execute(
        route,
        'Confirma a exclusão da chave',
        'Excluir',
        () => {
          this.$notify.success('Chave removida com sucesso.')
          this.getData()
        },
      )
    },
    onSave() {
      this.getData(1)
      this.isActivated = false
      this.currentContractKey = ''
    },
    onCancel() {
      this.isActivated = false
      this.currentContractKey = ''
    },
  },
}
</script>

<style scoped>

</style>
