<template>
  <i2-form-vue-select
    v-model="inputValue"
    :options="paymentMethodsAvailable"
    :name="name"
    :label="label"
  />
</template>
<script>
export default {
  name: 'PaymentMethodSelect',
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number, Object],
      default: '',
    },
  },
  data() {
    return {
      paymentMethodsAvailable: [],
      inputValue: '',
    }
  },
  watch: {
    value(data) {
      this.inputValue = data
    },
    inputValue(val) {
      this.$emit('input', val)
    },
  },
  mounted() {
    this.getPaymentMethods()
    this.inputValue = this.value
  },
  methods: {
    async getPaymentMethods() {
      this.paymentMethodsAvailable = await this.$http.get('payment-methods')
    },
  },
}
</script>
<style scoped>

</style>
