<template>
  <div>
    <b-row class="pb-1">
      <b-col class="text-right">
        <b-button
          variant="primary"
          @click="save"
        >
          <FeatherIcon icon="SaveIcon" />
          &nbsp;<span class="text-nowrap">Salvar</span>
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <treeselect
          v-model="model.stationsSelected"
          placeholder="Selecione..."
          :show-count="true"
          :multiple="true"
          :options="stationsAvailable"
          value-format="object"
          no-children-text="Vazio"
          always-open
          :default-expand-level="Infinity"
          is-default-expanded
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>

import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  name: 'StationsContract',
  components: { Treeselect },
  props: {
    contractUuid: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      model: {
        stationsSelected: null,
      },
      stationsAvailable: [],
    }
  },
  mounted() {
    this.getStations()
  },
  methods: {
    getStations() {
      this.$http.get('locations/treeview').then(
        response => {
          this.stationsAvailable = response

          if (this.contractUuid) {
            this.$http.get(`contracts/${this.contractUuid}/stations`).then(responseStations => {
              this.model.stationsSelected = responseStations
            })
          }
        },
      )
    },
    async save() {
      await this.$http.post(`contracts/${this.contractUuid}/stations`, this.model)
      this.$notify.success('Estações salvas com sucesso.')
    },
  },
}
</script>

<style scoped>

</style>
