<template>
  <b-container>
    <b-row>
      <b-col class="text-right">
        <b-button
          variant="primary"
          @click="newContract"
        >
          <FeatherIcon icon="PlusIcon" />
          &nbsp;<span class="text-nowrap">Contrato</span>
        </b-button>
      </b-col>
    </b-row>
    <Grid
      :fields="fields"
      :items="items"
      edit-button-label="edit"
      new-button-label="new"
      :show-search="false"
      @onChangePage="getData"
    >
      <template v-slot:search>
        <b-row class="p-0 pl-1">
          <b-col
            md="10"
            class="p-0 pr-1"
          >
            <b-form-input
              v-model="search.search"
              class="filter-input"
              placeholder="Pesquisar por Nome"
              name="nome"
              @keyup.enter="getData"
            />
          </b-col>
          <b-col
            sm="12"
            md="2"
          >
            <b-button
              variant="secondary"
              type="button"
              @click="getData"
            >
              <FeatherIcon icon="SearchIcon" />
              <span
                class="text-nowrap"
              />
            </b-button>
          </b-col>
        </b-row>
      </template>
      <template
        #cell(license_id)="data"
      >
        {{ data.item.license.uuid }}
      </template>
      <template
        #cell(action)="data"
        style="width: 20%"
      >
        <div class="px-1 text-center">
          <i2-icon-button
            icon="EditIcon"
            variant="outline-primary"
            class="mr-1"
            @click="setCurrentModel(data.item)"
          />
          <i2-icon-button
            fa-icon="fa-trash"
            variant="outline-danger"
            class="mr-1"
            @click="onDelete(data.item)"
          />

        </div>
      </template>
    </Grid>
    <license-contract-form
      :is-active.sync="isActivated"
      :uuid="currentModel"
      :license-uuid="license.uuid"
      @onSave="onSave()"
      @onCancel="onCancel()"
    />
  </b-container>

</template>

<script>

import LicenseContractForm from '@/views/license/LicenseContractForm.vue'

export default {
  name: 'LicenseContractList',
  components: { LicenseContractForm },
  props: {
    license: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      search: {
        search: '',
      },
      fields: [
        {
          key: 'number_licenses',
          label: 'Numero de Licenças',
          class: 'w-7',
        },
        {
          key: 'buy_date',
          label: 'Data da compra',
          class: 'w-7',
        },
        {
          key: 'license_type',
          label: 'Tipo de Licença',
          class: 'w-7',
        },
        {
          key: 'supplier',
          label: 'Fornecedor',
          class: 'w-7',
        },
        {
          key: 'total_value',
          label: 'Valor Total',
          class: 'w-7',
        },
        {
          key: 'expiration_date',
          label: 'Validade',
          class: 'w-7',
        },
        {
          key: 'action',
          label: 'Ações',
          class: 'w-20 text-center',
        },
      ],
      currentModel: '',
      items: [],
      isActivated: false,
      currentLicense: {},
    }
  },
  async mounted() {
    if (this.license) {
      this.currentLicense = this.license
    }
    await this.getData(1)
  },
  methods: {
    newModel() {
      this.isActivated = true
    },
    async getData(page) {
      this.items = await this.$search.get(
        `licenses/${this.$route.params.uuid}/contracts`,
        page,
        this.search,
        '',
      )
    },

    setCurrentModel(model) {
      this.isActivated = true
      this.currentModel = model.uuid
    },

    onDelete(model) {
      const route = `contracts/${model.uuid}`
      this.$apiDelete.execute(
        route,
        'Confirma a exclusão do Contrato?',
        'Excluir',
        () => {
          this.$notify.success('Contrato removido com sucesso.')
          this.getData()
        },
      )
    },
    onSave() {
      this.getData(1)
      this.isActivated = false
      this.currentModel = ''
    },
    onCancel() {
      this.isActivated = false
      this.currentModel = ''
    },
    newContract() {
      this.isActivated = true
      this.currentModel = ''
    },
  },
}
</script>

<style scoped>

</style>
