<template>
  <b-container>
    <page-header />
    <b-card>
      <h2 class="mb-1">
        {{ license.name }}
      </h2>
      <b-tabs>
        <b-tab title="Contratos" active>
          <LicenseContractList :license="license" />
        </b-tab>
        <b-tab title="Software" :disabled="license.type !== 'software'">
          <b-row>
            <b-col md="6">
              <i2-form-vue-select
                v-model="model.hasSoftware"
                label="Software listado em adicionar/remover no Windows?"
                :options="hasSoftwares"
                name="softwareType"
                @input="changeHasSoftware"
              />
            </b-col>
            <b-col md="6">
              <i2-form-vue-select
                v-model="model.softwareType"
                label="Tipo de Software"
                :options="softwareTypes"
                name="softwareType"
                :disabled="model.hasSoftware.value === 'unlisted'"
                @input="changeSoftwareType"
              />
            </b-col>
          </b-row>
          <b-row v-show="model.softwareType.value === 'installed'">
            <b-col>
              <installed-software :license-uuid="license.uuid" :start="startInstalled" :block-button="model.hasSoftware.value === 'unlisted'" />
            </b-col>
          </b-row>
          <b-row v-show="model.softwareType.value === 'web'">
            <b-col>
              <web-software :license-uuid="license.uuid" :start="startWeb" :block-button="model.hasSoftware.value === 'unlisted'" />
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
    </b-card>
  </b-container>

</template>

<script>

import LicenseContractList from '@/views/license/LicenseContractList.vue'
import InstalledSoftware from '@/components/software/InstalledSoftware.vue'
import WebSoftware from '@/components/software/WebSoftware.vue'

export default {
  name: 'LicenseDetails',
  components: {
    WebSoftware, InstalledSoftware, LicenseContractList,
  },
  data() {
    return {
      search: {
        search: '',
      },
      currentModel: '',
      startInstalled: false,
      startWeb: false,
      license: { uuid: '' },
      softwareTypes: [
        {
          text: 'Instalado',
          value: 'installed',
        },
        {
          text: 'Web',
          value: 'web',
        },
      ],
      hasSoftwares: [
        {
          text: 'Listado',
          value: 'listed',
        },
        {
          text: 'Não Listado',
          value: 'unlisted',
        },
      ],
      model: {
        softwareType: {
          text: 'Instalado',
          value: 'installed',
        },
        hasSoftware: {
          text: 'Listado',
          value: 'listed',
        },
      },
    }
  },
  mounted() {
    this.license.uuid = this.$route.params.uuid
    this.getData()
  },
  methods: {
    async getData() {
      this.license = await this.$http.get(`licenses/${this.license.uuid}`)
    },
    changeSoftwareType() {
      if (this.model.softwareType.value === 'installed') {
        this.startInstalled = true
        this.startWeb = false
      }

      if (this.model.softwareType.value === 'web') {
        this.startInstalled = false
        this.startWeb = true
      }
    },
    changeHasSoftware() {
      if (this.model.hasSoftware.value === 'unlisted') {
        let message = 'Você confirma que o Software instalado não aparece na lista de programas?'
        message += 'A seleção desta opção irá excluir todos os programas já associados a esta licença.'

        this.$bvModal
          .msgBoxConfirm(message, {
            title: 'Você confirma esta operação?',
            size: 'md',
            okVariant: 'danger',
            okTitle: 'Sim, eu confirmo!',
            cancelTitle: 'Não, quero cancelar.',
            cancelVariant: 'outline-primary',
            hideHeaderClose: true,
          })
          .then(async value => {
            if (value) {
              this.startInstalled = false
              this.startWeb = false
              await this.$http.delete(`licenses/${this.license.uuid}/all-softwares`)
              this.$notify.success('Os softwares vinculados foram excluídos com sucesso.')
            } else {
              this.model.hasSoftware = {
                text: 'Listado',
                value: 'listed',
              }
              this.startInstalled = true
              this.startWeb = false
            }
          })
      }
    },
  },
}
</script>

<style scoped>

</style>
