<template>
  <b-modal
    id="form-sidebar"
    v-model="isActive"
    size="lg"
    backdrop
    :no-close-on-backdrop="true"
    bg-variant="white"
    title="Informações do Contrato"
    right
    shadow
    fullscreen
    :hide-footer="true"
    @hidden="onCancel"
    @hide="onCancel"
    @change="(val) => $emit('update:is-active', val)"
  >
    <b-card>
      <b-tabs>
        <b-tab @click="setCurrentTab('informations')">
          <template #title>
            <feather-icon icon="FileTextIcon" />
            Informações
          </template>

          <template #default="{ hide }">
            <validation-observer
              #default="{ handleSubmit }"
              ref="refFormObserver"
            >

              <!-- Form -->
              <b-form
                class="p-2"
                @submit.prevent="handleSubmit(onSubmit)"
              >
                <b-row>
                  <b-col
                    sm="4"
                    md="4"
                  >
                    <i2-form-input
                      v-model="model.number_licenses"
                      type="number"
                      rules="required"
                      label="Numero de Licenças"
                      placeholder=""
                      name="number_licenses"
                      @input="processValueTotal"
                    />

                  </b-col>
                  <b-col
                    sm="4"
                    md="4"
                  >
                    <i2-form-input-date
                      v-model="model.buy_date"
                      rules="required"
                      label="Data da compra"
                      placeholder=""
                      name="buy_date"
                    />
                  </b-col>
                  <b-col
                    sm="4"
                    md="4"
                  >
                    <s-auto-complete
                      v-model="model.license_type"
                      text-field="text"
                      value-field="value"
                      endpoint="/contracts/license-type?page=1&search=license_type:"
                      name="license_type"
                      label="Tipo de Licença"
                      no-option-message="Digite para pesquisar..."
                      @input="changeLicenseType"
                    />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    sm="12"
                    md="12"
                  >
                    <s-auto-complete
                      v-model="model.supplier"
                      text-field="text"
                      value-field="value"
                      endpoint="/contracts/supplier?page=1&search=supplier:"
                      name="supplier"
                      label="Fornecedor"
                      no-option-message="Digite para pesquisar..."
                      @input="changeSupplier"
                    />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    sm="6"
                    md="6"
                  >
                    <i2-form-input-money
                      v-model="model.total_value"
                      rules="required"
                      label="Valor Total"
                      placeholder=""
                      name="total_value"
                      @input="processUnitValue"
                    />

                  </b-col>
                  <b-col
                    sm="6"
                    md="6"
                  >
                    <i2-form-input-money
                      v-model="model.unit_value"
                      rules="required"
                      label="Valor Unitário"
                      placeholder=""
                      name="unit_value"
                      @input="processValueTotal"
                    />

                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    sm="6"
                    md="6"
                  >
                    <payment-method-select
                      v-model="model.payment_method"
                      name="payment_method"
                      label="Forma de Pagamento"
                    />
                  </b-col>
                  <b-col
                    sm="6"
                    md="6"
                  >
                    <i2-form-input
                      v-model="model.invoice"
                      name="invoice"
                      label="Nota Fiscal"
                      placeholder=""
                    />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    sm="6"
                    md="6"
                  >
                    <i2-form-input-date
                      v-model="model.expiration_date"

                      label="Validade"
                      placeholder=""
                      name="expiration_date"
                    />
                  </b-col>
                  <b-col
                    sm="6"
                    md="6"
                  >
                    <i2-form-input
                      v-model="model.warning_days"
                      type="number"

                      rules="required"
                      label="Avisar em"
                      placeholder=""
                      name="warning_days"
                    />

                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    sm="12"
                    md="12"
                  >
                    <i2-form-textarea
                      v-model="model.observation"

                      label="Observação"
                      placeholder=""
                      name="observation"
                    />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    sm="12"
                    md="12"
                    class="text-right"
                  >

                    <!-- Form Actions -->
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      type="button"
                      class="mr-2"
                      @click="onSubmit"
                    >

                      <feather-icon
                        icon="SaveIcon"
                        class="mr-50"
                      />
                      Salvar
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-danger"
                      type="button"
                      @click="onCancel"
                    >

                      <font-awesome-icon
                        prefix="far"
                        :icon="['far', 'times-circle']"
                        class="mr-50"
                      />
                      Cancelar
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </template>
        </b-tab>
        <b-tab style="height: 500px" :disabled="!model.uuid" @click="setCurrentTab('keys')">
          <template #title>
            <feather-icon icon="KeyIcon" />
            Chaves
          </template>
          <license-contract-key-list :contract-uuid="model.uuid" />
        </b-tab>
        <b-tab :disabled="!model.uuid" @click="setCurrentTab('attach')">
          <template #title>
            <feather-icon icon="PaperclipIcon" />
            Anexos
          </template>
          <LicenseContractFileList :contract-uuid="model.uuid" />
        </b-tab>
        <b-tab style="height: 500px" :disabled="!model.uuid" @click="setCurrentTab('stations')">
          <template #title>
            <feather-icon icon="HardDriveIcon" />
            Ativos
          </template>
          <stations-contract :contract-uuid="model.uuid" />
        </b-tab>
      </b-tabs>
    </b-card>
  </b-modal>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import { alphaNum, required } from '@validations'
import { ValidationObserver } from 'vee-validate'
import LicenseContractKeyList from '@/views/license/LicenseContractKeyList.vue'
import moment from 'moment-timezone'
import LicenseContractFileList from '@/views/license/LicenseContractFileList.vue'
import StationsContract from '@/components/software/StationsContract.vue'
import PaymentMethodSelect from '@/components/forms/PaymentMethodSelect.vue'

export default {
  name: 'LicenseContractForm',
  directives: {
    Ripple,
  },
  components: {
    PaymentMethodSelect,
    StationsContract,
    LicenseContractFileList,
    LicenseContractKeyList,
    ValidationObserver,
  },
  props: {
    uuid: {
      type: String,
      required: true,
    },
    licenseUuid: {
      type: String,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      currentTab: 'informations',
      contract: {},
      suppliersAvailable: [],
      model: {
        uuid: '',
        license_id: '',
        number_licenses: '',
        buy_date: '',
        license_type: '',
        supplier: '',
        total_value: '',
        invoice: '',
        unit_value: '',
        expiration_date: '',
        warning_days: '',
        observation: '',
        payment_method: '',
      },
    }
  },
  watch: {
    uuid(uuid) {
      if (uuid) {
        this.getData(uuid)
      }
    },
  },
  methods: {
    onSubmit() {
      this.$refs.refFormObserver.validate().then(async success => {
        if (success) {
          let response = {}
          const data = { ...this.model }
          for (const field in data) {
            data[field] = data[field]
            if (data[field] != null && data[field].value) {
              data[field] = data[field].value
            }
          }

          data.license_uuid = this.licenseUuid
          if (data.buy_date) {
            data.buy_date = moment(data.buy_date, 'DD/MM/YYYY').format('YYYY-MM-DD')
          }
          if (data.expiration_date) {
            data.expiration_date = moment(data.expiration_date, 'DD/MM/YYYY').format('YYYY-MM-DD')
          }
          if (this.model.uuid) {
            response = await this.$http.put(`contracts/${this.model.uuid}`, data)
          } else {
            response = await this.$http.post('contracts', data)
          }
          if (response.error) {
            this.$notify.error(response.error_message)
            return
          }
          this.$notify.success('Contrato salvo com sucesso.')
          this.$emit('onSave', true)
          this.resetModel()
        }
      })
    },
    onCancel() {
      this.resetModel()
      this.$refs.refFormObserver.reset()
      this.$emit('onCancel', true)
    },
    async getData(uuid) {
      const response = await this.$http.get(`contracts/${uuid}`)
      this.model = response
      this.model.supplier = { text: response.supplier, value: response.supplier }
    },
    resetModel() {
      this.model = {
        uuid: '',
        license_id: '',
        number_licenses: '',
        buy_date: '',
        license_type: '',
        supplier: { text: '', value: '' },
        total_value: '',
        invoice: '',
        unit_value: '',
        expiration_date: '',
        warning_days: '',
        observation: '',
        payment_method: '',
      }
      this.contract = {}
      this.$refs.refFormObserver.reset()
    },
    setCurrentTab(tab) {
      this.currentTab = tab
    },
    processValueTotal() {
      if (this.model.unit_value && this.model.number_licenses) {
        this.model.total_value = Number(this.model.unit_value) * Number(this.model.number_licenses)
      }
    },
    processUnitValue() {
      if (this.model.total_value && this.model.number_licenses) {
        this.model.unit_value = Number(this.model.total_value) / Number(this.model.number_licenses)
      }
    },
    changeSupplier(value) {
      this.model.supplier.text = value.text
      this.model.supplier.value = value.text
    },
    changeLicenseType(value) {
      this.model.license_type.text = value.text
      this.model.license_type.value = value.text
    },
  },
}
</script>

<style scoped>
</style>
