<template>
  <div>
    <b-row>
      <b-col class="text-right">
        <b-button
          variant="primary"
          :disabled="blockButton"
          @click="save"
        >
          <FeatherIcon icon="SaveIcon" />
          &nbsp;<span class="text-nowrap">Salvar</span>
        </b-button>
      </b-col>
    </b-row>
    <Grid
      :fields="fields"
      :items="items"
      edit-button-label="edit"
      new-button-label="new"
      :show-search="false"
      @onChangePage="changePage"
    >
      <template v-slot:search>
        <b-row class="p-0 pl-1">
          <b-col
            md="10"
            class="p-0 pr-1"
          >
            <b-form-input
              v-model="search.search"
              class="filter-input"
              placeholder="Pesquisar por Nome"
              name="nome"
              @keyup.enter="getItems"
            />
          </b-col>
          <b-col
            sm="12"
            md="2"
          >
            <b-button
              variant="secondary"
              type="button"
              @click="getItems"
            >
              <FeatherIcon icon="SearchIcon" />
              <span
                class="text-nowrap"
              />
            </b-button>
          </b-col>
        </b-row>
      </template>
      <template #cell(selection)="data">
        <b-form-checkbox
          v-model="data.item.installed"
          @change="selected(data.item)"
        />
      </template>
    </Grid>
    <b-row>
      <b-col class="text-right">
        <b-button
          variant="primary"
          :disabled="blockButton"
          @click="save"
        >
          <FeatherIcon icon="SaveIcon" />
          &nbsp;<span class="text-nowrap">Salvar</span>
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>
<script>

export default {
  name: 'WebSoftware',
  props: {
    licenseUuid: {
      required: true,
      type: String,
    },
    start: {
      required: true,
      type: Boolean,
    },
    blockButton: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      search: {
        search: '',
      },
      fields: [
        {
          key: 'selection',
          label: 'Selecione',
          class: 'w-10',
        },
        {
          key: 'name',
          label: 'Nome',
        },
      ],
      items: [],
      selectedItems: [],
      webSoftwares: [],
    }
  },
  watch: {
    licenseUuid(value) {
      if (value) {
        this.getWebSoftwares()
        this.getItems(1)
      }
    },
    start(value) {
      if (value) {
        this.getWebSoftwares()
        this.getItems(1)
      }
    },
  },
  methods: {
    async getItems(page) {
      const items = await this.$search.get(
        'web-software',
        page,
        this.search,
        '',
      )

      if (this.webSoftwares.length > 0) {
        this.webSoftwares.map(software => {
          items.data.map(itemGrid => {
            if (itemGrid.uuid === software.web_software.uuid) {
              itemGrid.installed = true
            }
          })
        })
      }
      this.items = items
    },
    selected(item) {
      this.selectedItems = this.selectedItems.filter(selectedItem => selectedItem.uuid !== item.uuid)
      this.selectedItems.push(item)
    },
    async getWebSoftwares() {
      this.webSoftwares = await this.$http.get(`licenses/${this.licenseUuid}/web-softwares`)
    },
    async save() {
      if (this.selectedItems.length === 0) {
        return false
      }
      await this.$http.post(`licenses/${this.licenseUuid}/web-softwares`, this.selectedItems)
      this.$notify.success('Softwares vinculados com sucesso.')
      return true
    },
    async changePage(page) {
      await this.save()
      await this.getItems(page)
    },
  },
}
</script>

<style scoped>

</style>
